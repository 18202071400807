<template>
      <div>
          <el-button type="primary" :loading="loading" @click="openDialog()">新建</el-button>
<!--          <el-button v-if="is_dev()" type="primary" :loading="loading" @click="importItems()">导入</el-button>-->
      </div>

      <div style="display: inline-flex;width: 100%; margin-top: 10px">
        <el-select v-model="query.scene_id" placeholder="场景" clearable style="width: 200px" :disabled="loading">
          <el-option v-for="c in scene_list" :label="c.i18n_name.EN" :value="c.id"></el-option>
        </el-select>
        <el-select v-model="query.category_id" placeholder="分类" clearable style="width: 200px" :disabled="loading">
          <el-option v-for="c in category_list" :label="c.i18n_name.EN" :value="c.id"></el-option>
        </el-select>
        <el-select v-model="query.recommended" placeholder="是否推荐" clearable style="width: 200px" :disabled="loading">
          <el-option v-for="c in [true, false]" :label="c ? 'Yes' : 'No'" :value="c"></el-option>
        </el-select>
        <el-input v-model="query.id" style="width: 200px" placeholder="物品ID" clearable :disabled="loading"></el-input>
<!--        <el-button type="primary" :loading="loading" @click="openDialog()">创建</el-button>-->
<!--        <el-button type="success" :loading="loading" @click="$refs.tools.open()">工具</el-button>-->
      </div>
      <el-table :data="dataPage" v-loading="loading" style="margin-top: 10px">
          <el-table-column prop="id" width="150" label="ID"></el-table-column>
          <el-table-column label="活跃" prop="active" width="100">
            <template #default="scope">
              <el-switch v-model="scope.row.active" :disabled="loading" @change="update(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="thumbnail" width="150" label="缩略图">
            <template #default="scope">
              <el-image lazy :src="`${$root.env.CDN_URL}/${scope.row.thumbnail}`"
                        style="width: 100px"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="finished" width="150" label="完成图">
            <template #default="scope">
              <el-image lazy :src="`${$root.env.CDN_URL}/${scope.row.finished}`"
                        style="width: 100px"></el-image>
            </template>
          </el-table-column>
<!--          <el-table-column prop="scene_id" width="150" label="场景">-->
<!--            <template #default="scope">-->
<!--              <el-image lazy :src="`${$root.env.CDN_URL}/${getSceneImage(scope.row.scene_id)}`"-->
<!--                        style="width: 100px"></el-image>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column prop="scenes" width="150" label="场景">
            <template #default="scope">
              <el-image v-if="scope.row.scenes && scope.row.scenes.length === 1" lazy
                        :src="`${$root.env.CDN_URL}/${scope.row.scenes[0].cover}`" style="width: 100px"></el-image>
              <el-tag v-else v-for="s in scope.row.scenes">{{ s.name }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="category_id" width="100" label="分类">
            <template #default="scope">
              <template v-for="cc in category_list">
                <el-tag size="small" v-if="cc.id===scope.row.category_id">{{ cc.i18n_name.EN }}</el-tag>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="recommended" width="80" label="是否推荐">
            <template #default="scope">
                <el-tag type="danger" size="small" v-if="scope.row.recommended">{{ 'Yes' }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="sites" width="80" label="有无坐标">
            <template #default="scope">
              <el-tag type="danger" size="small" v-if="scope.row.sites.length > 0">{{ 'Yes' }}</el-tag>
              <span v-else>{{ '' }}</span>
            </template>
          </el-table-column>
<!--          <el-table-column prop="x" width="80" label="X坐标"></el-table-column>-->
<!--          <el-table-column prop="y" width="80" label="Y坐标"></el-table-column>-->
          <el-table-column prop="width" width="80" label="宽度"></el-table-column>
          <el-table-column prop="height" width="80" label="高度"></el-table-column>
<!--            <el-table-column label="图片">-->
<!--                <template #default="scope">-->
<!--                    <vue-draggable-next v-model="scope.row.images" :animation="300" handle=".handle"-->
<!--                                        @change="sort(scope.row)" class="gallery">-->
<!--                        <template v-for="i in scope.row.images">-->
<!--                            <div class="item handle" style="position: relative;" @click="openDialog1(scope.row, i)">-->
<!--                                <el-image style="width: 100px;height: 100px;" lazy-->
<!--                                          :src="`${$root.env.CDN_URL}/${i.thumbnail}`"></el-image>-->
<!--                                <el-icon size="20" @click.stop="remove1(scope.row, i)"-->
<!--                                         style="position: absolute;color: var(&#45;&#45;el-color-danger); top: 0;right: 0;">-->
<!--                                    <CircleCloseFilled/>-->
<!--                                </el-icon>-->
<!--                            </div>-->
<!--                        </template>-->
<!--                        <el-card class="item" shadow="never" @click="openDialog1(scope.row)">-->
<!--                            <Plus style="color: var(&#45;&#45;el-text-color-secondary)"/>-->
<!--                        </el-card>-->
<!--                    </vue-draggable-next>-->
<!--                </template>-->
<!--            </el-table-column>-->
          <el-table-column width="280" label="操作">
              <template #default="scope">
                  <el-button v-if="!this.query.id" type="success" circle icon="Top" :loading="loading"
                             :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                  <el-button v-if="!this.query.id" type="success" circle icon="Bottom" :loading="loading"
                             :disabled="scope.$index===dataFilter.length - 1" @click="moveDown(scope.$index)"></el-button>
                  <el-input v-if="!this.query.id" v-model="scope.row.sort" :disabled="loading" style="margin-left:5px; margin-right:5px; width: 60px"
                            @change="changeSort(scope.row)"></el-input>
                  <el-button :disabled="loading" @click="openDialog(scope.row)" circle icon="Edit"></el-button>
                  <el-button type="danger" :disabled="loading" @click="remove(scope.row)" circle
                             icon="Delete"></el-button>
              </template>
          </el-table-column>
      </el-table>
      <div style="margin-top: 10px;text-align: center;">
        <el-pagination v-model:current-page="page" :page-size="size" layout="prev, pager, next"
                       :total="dataFilter.length"></el-pagination>
      </div>
      <el-dialog v-model="dialog_opened" width="80%" :before-close="reset">
        <el-form label-width="100px" ref="form" :model="form">
<!--            <el-form-item label="图片" prop="image_id" :rules="[{required: true, message: '请选择图片'}]">-->
<!--                <el-radio-group v-model="form.image_id" :disabled="loading">-->
<!--                    <el-radio v-for="i in dataFilter" :label="i.id" style="margin: 30px 0">-->
<!--                        <el-image lazy style="width: 100px;"-->
<!--                                  :src="`${$root.env.CDN_URL}/${i.thumbnail}`"></el-image>-->
<!--                    </el-radio>-->
<!--                </el-radio-group>-->
<!--            </el-form-item>-->
            <el-form-item label="图片" prop="image_id" :rules="[{required: true, message: '请选择图片'}]">
              <el-input v-model="form.image_id" prefix-icon="Search" placeholder="ID" style="margin-left:5px; width: 220px"></el-input>
<!--              <template v-for="image in image_list">-->
<!--                <el-image v-if="form.image_id === image.id" style="width: 160px"-->
<!--                          :src="`${$CDN_URL}/${image.thumbnail}`"></el-image>-->
<!--              </template>-->
            </el-form-item>
            <el-form-item label="完成图" prop="finished" :rules="[{required: false, message: '请上传完成图'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleFinishedSuccess"
                           accept="image/*" :data="{prefix: 'scape'}" :onProgress="handleProgress"
                           :before-upload="beforeUpload"
                           :headers="{authorization: $root.authorization}">
                    <img v-if="form.finished" :src="`${$root.env.CDN_URL}/${form.finished}`" style="width: 100px">
                    <el-button v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="场景" prop="scenes" :rules="[{required: true, message: '请选择场景'}]">
              <el-select v-model="scenesValue" style="width: 100%;" filterable multiple :disabled="loading">
                <template v-for="c in scene_list">
                  <el-option :value="c.id" :label="c.i18n_name.EN"></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="分类" prop="category_id" :rules="[{required: true, message: '请选择分类'}]">
              <el-select v-model="form.category_id" style="width: 100%" filterable :disabled="loading">
                <template v-for="c in category_list">
                  <el-option :value="c.id" :label="c.i18n_name.EN"></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="是否推荐" prop="recommended">
              <el-checkbox v-model="form.recommended" :disabled="loading">推荐物品</el-checkbox>
            </el-form-item>
<!--            <el-form-item label="X坐标" prop="x" :rules="[{required: form.recommended, message: '请输入数字', type: 'number'}]">-->
<!--                <el-input-number v-model="form.x" :disabled="loading"></el-input-number>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="Y坐标" prop="y" :rules="[{required: form.recommended, message: '请输入数字', type: 'number'}]">-->
<!--                <el-input-number v-model="form.y" :disabled="loading"></el-input-number>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="宽度" prop="w" :rules="[{required: form.recommended, message: '请输入数字', type: 'number'}]">-->
<!--                <el-input-number v-model="form.w" :disabled="loading"></el-input-number>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="高度" prop="h" :rules="[{required: form.recommended, message: '请输入数字', type: 'number'}]">-->
<!--                <el-input-number v-model="form.h" :disabled="loading"></el-input-number>-->
<!--            </el-form-item>-->
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update, updateAll} from "../../libs/utils";
import {ElMessageBox} from 'element-plus';
import {VueDraggableNext} from 'vue-draggable-next'
import DateRangePicker from "../../base/DateRangePicker.vue";
import {is_dev} from "../../libs/permission";

export default {
    name: "Item",
    props: {
        image_list: Array,
    },
    components: {DateRangePicker, VueDraggableNext},
    data() {
        return {
            loading: false, scene: null, opened: false, data: [], category_list: [], scene_list: [],
            editing: null, dialog_opened: false, scene_id_map: {}, scene_name_map: {}, scene_name_list: [],
            page: 1, size: 50,
            form: {
                scenes: [],
                finished: '', //"resource/dbcf219c4eb3ebca73de5dbe43e28697.png",
                image_id: '',
                // site_id: null,
                sites: [],
                category_id: '',
                recommended: null,
                width: null,
                height: null,
                active: null,
            },
            query: {
                id: null,
                scene_id: null,
                category_id: null,
                recommended: null,
            },
        }
    },
    methods: {
      is_dev,
        init() {
            // this.scene = scene;
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/scape/item`, this.form).then(res => {
                    this.data = res.data.data.items;
                    this.data.sort((a, b) => (a.sort || 0) - (b.sort || 0))
                }),
                axios.get('/cms/v1/scape/category').then(res => {
                    this.category_list = res.data.data.categoryList;
                }),
                axios.get(`/cms/v1/scene`).then(res => {
                    this.scene_list = res.data.data.sceneList;
                    this.scene_list.forEach(s => {
                        this.scene_id_map[s.id] = s
                        this.scene_name_map[s.i18n_name.EN] = s
                        this.scene_name_list.push(s.i18n_name.EN)
                    })
                })
            ]).then(_ => {
                this.loading = false;
            })
        },
        close() {
            this.loading = this.opened = false;
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            });
        },
        importItems() {
            axios.get(`/cms/v1/scape/import/item`)
        },
        reset() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/scape/item/${this.editing.id}`, this.form).then(res => {
                            updateAll(this.editing, res.data.data);
                            this.reset();
                            this.init();
                        })
                    } else {
                        // this.form.scene_id = this.scene.id;
                        axios.post(`/cms/v1/scape/item`, this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.reset()
                            this.init();
                        })
                    }
                }
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/scape/item/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            })
        },
        moveUp(index) {
          const before = this.dataFilter[index - 1]
          const current = this.dataFilter[index]
          current.sort = before.sort - 1
          this.data.sort((a, b) => (a.sort || 0) - (b.sort || 0))
          this.moveSort()
        },
        moveDown(index) {
          const after = this.dataFilter[index + 1]
          const current = this.dataFilter[index]
          current.sort = after.sort + 1
          this.data.sort((a, b) => (a.sort || 0) - (b.sort || 0))
          this.moveSort()
        },
        moveSort() {
            const sort = [];
            this.data.forEach((d, index) => {
                sort.push(d.id);
                d.sort = index;
            });
            // this.loading = true;
            axios.post(`/cms/v1/scape/sort/item`, {sort}).then(_ => {
                // this.loading = false;
            });
        },
        getSceneName(id) {
            for (let i = 0; i < this.scene_list.length; i++) {
              if (this.scene_list[i].id === id) {
                return this.scene_list[i].i18n_name['EN'];
              }
            }
            return ""
        },
        getSceneImage(id) {
          for (let i = 0; i < this.scene_list.length; i++) {
            if (this.scene_list[i].id === id) {
              return this.scene_list[i].cover
            }
          }
          return ""
        },
        handleFinishedSuccess(res, file) {
            this.loading = false;
            this.form.finished = res.data.name
        },
        beforeUpload(file) {
        },
        handleProgress() {
            this.loading = true;
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/scape/item/${row.id}`, row).then(_ => {
                this.loading = false;
            });
        },
        changeSort(row) {
          // let found_self = false
          // let found_before = false
          let row_sort = parseInt(row.sort || 0)
          const current_index = this.dataFilter.indexOf(row)
          const target_index = this.dataFilter.findIndex(e => parseInt(e.sort || '0') === row_sort);
          const is_move_up = current_index > target_index && target_index >= 0
          if (is_move_up) {
              row.sort = row_sort - 1
          }
          // this.dataFilter.forEach((d, i) => {
          //     const sort = parseInt(d.sort || '0')
          //     if(row.id !== d.id && sort === row_sort && !found_self && i <= row_sort) {
          //         if (i < row_sort) {
          //            d.sort = sort - 1
          //         }
          //         found_before = true
          //     }
          //     if(row.id === d.id) {
          //         found_self = true
          //     }
          //     if(row.id !== d.id && sort === row_sort && found_self && i >= row_sort) {
          //         if(i > row_sort) {
          //             d.sort = sort + 1
          //         }
          //     }
          //     if (i === row_sort) {
          //         if(found_before) {
          //             row.sort = row_sort - 1
          //         } else {
          //             row.sort = row_sort + 1
          //         }
          //     }
          // });
          this.data.sort((a, b) => (a.sort || 0) - (b.sort || 0))
          const sort = [];
          this.dataFilter.forEach((d, index) => {
            sort.push(d.id);
            d.sort = index;
          });
          // this.loading = true;
          axios.post(`/cms/v1/scape/sort/item`, {sort}).then(_ => {
            // this.loading = false;
          });
        },
    },
    mounted() {
      this.init();
    },
    computed: {
        dataFilter() {
            return this.data.filter(d => {
                return (
                    (!this.query.id || d.id === this.query.id)
                    && (!this.query.category_id || d.category_id === this.query.category_id)
                    && (!this.query.scene_id || d.scenes.some(scene => scene.id === this.query.scene_id))
                    && ( this.query.recommended == null || (this.query.recommended && d.recommended) ||
                        (!this.query.recommended && !d.recommended))
                )
            })
        },
        dataPage() {
          return this.dataFilter.slice((this.page - 1) * this.size, this.page * this.size);
        },
        scenesValue: {
            get() {
                return this.form.scenes.map(s=>s.id)
            },
            set(values) {
                this.form.scenes = values.map(value => ({ id:value, name: this.getSceneName(value), cover: this.getSceneImage(value)}));
            }
        },
    },
}
</script>

<style scoped>
.gallery {
    display: flex;
}

.gallery .item {
    margin: 0 5px;
    width: 100px;
    height: 100px;
    cursor: pointer;
}
</style>